import { gql, useLazyQuery } from "@apollo/client";
import { Card, Collapse, Fade, Icon, Modal, Slide, Tooltip } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import { useApolloClient } from "context/apolloClientContext";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { getUproColor } from "utils/colors";
import { formatMoneyPunto, makeNiceText } from "utils/formatters";

const foodIcons = {
  Entrada: "restaurant",
  "Desayuno / Merienda": "emoji_food_beverage",
  "Plato principal": "lunch_dining",
  Postre: "icecream",
  Ensaladas: "rice_bowl",
  "Bebidas sin alcohol": "water_drop_outlined",
  "Bebidas con alcohol": "local_bar",
};

const getFoodIcon = (categoria) => {
  return foodIcons[categoria] || "restaurant";
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxHeight: "90%",
  width: { xs: "90%", sm: "40%", xxl: "30%" },
  overflowY: "auto",
  p: 3,
};

export default function ModalDetallesPedido({ open, handleClose, idPedido }) {
  const client = useApolloClient("clientComedor");
  const [pedido, setPedido] = useState(null);

  const [getPedido] = useLazyQuery(
    gql`
      query pedido($id: ID!) {
        pedido(id: $id) {
          createdAt
          id
          fechaPedido
          total
          estado
          cuentaCorriente
          venta {
            id
            url
            pagos {
              medioDePago {
                nombre
              }
            }
          }
          detalles {
            cantidad
            precio
            producto {
              nombre
              id
              categoriaProducto {
                categoria
              }
            }
          }
        }
      }
    `,
    {
      client,
    }
  );

  useEffect(() => {
    if (open && idPedido) {
      getPedido({
        variables: {
          id: idPedido,
        },
      }).then((res) => {
        setPedido(res.data.pedido);
      });
    }
  }, [open]);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={() => {
        handleClose();
      }}
      closeAfterTransition
    >
      <Fade in={open}>
        <Card sx={style}>
          <SoftBox>
            <SoftBox display="flex" justifyContent="space-between">
              <SoftBox>
                <SoftTypography variant="h6">Detalles del pedido</SoftTypography>
              </SoftBox>
              <SoftBox display="flex" justifyContent="space-between">
                <SoftBox>
                  <Tooltip title="Cerrar" placement="top">
                    <Icon fontSize="medium" onClick={handleClose} sx={{ cursor: "pointer" }}>
                      close
                    </Icon>
                  </Tooltip>
                </SoftBox>
              </SoftBox>
            </SoftBox>
            <SoftBox pt={3}>
              <SoftBox display="flex" flexDirection="column" justifyContent="space-between" gap={2}>
                <Slide in={open} direction="up" mountOnEnter unmountOnExit>
                  <SoftBox>
                    <Card
                      sx={{
                        width: "100%",
                      }}
                    >
                      <SoftBox
                        p={2}
                        bgColor={
                          pedido?.estado === "RESERVADO"
                            ? getUproColor("uproYellow")
                            : pedido?.estado === "EN PREPARACIÓN"
                            ? getUproColor("uproPink")
                            : pedido?.estado === "CERRADO"
                            ? getUproColor("uproGreen")
                            : getUproColor("uproPink")
                        }
                      >
                        <SoftBox display="flex" justifyContent="center">
                          <Icon
                            fontSize="medium"
                            sx={{
                              color: "#fff",
                              marginRight: 1,
                              animation: "shakes 1s",
                              "@keyframes shakes": {
                                "0%": {
                                  transform: "rotate(0deg)",
                                },
                                "25%": {
                                  transform: "rotate(10deg)",
                                },
                                "50%": {
                                  transform: "rotate(-20deg)",
                                },
                                "75%": {
                                  transform: "rotate(15deg)",
                                },
                                "100%": {
                                  transform: "rotate(-5deg)",
                                },
                              },
                            }}
                          >
                            {pedido?.estado === "RESERVADO"
                              ? "schedule"
                              : pedido?.estado === "EN PREPARACIÓN"
                              ? "restaurant"
                              : pedido?.estado === "CERRADO"
                              ? "check_circle"
                              : "restaurant"}
                          </Icon>
                          <SoftTypography
                            variant="h5"
                            fontWeight="bold"
                            textAlign="center"
                            color="white"
                          >
                            {`Pedido ${makeNiceText(pedido?.estado)}`}
                          </SoftTypography>
                        </SoftBox>
                      </SoftBox>
                    </Card>
                  </SoftBox>
                </Slide>
                <Slide in={open} direction="up" mountOnEnter unmountOnExit>
                  <SoftBox>
                    <Card
                      sx={{
                        width: "100%",
                      }}
                    >
                      <SoftBox p={2}>
                        <SoftBox display="flex" alignItems="center">
                          <SoftBox mr={1} display="flex" alignItems="center">
                            <Icon color="primary">event_available</Icon>
                          </SoftBox>
                          <SoftTypography variant="h6">
                            Fecha del pedido&nbsp;
                            <Tooltip
                              placement="top"
                              title="Fecha en la que retirarás el pedido. No podrás retirarlo ni antes de la fecha ni pasada la misma."
                            >
                              <Icon
                                sx={{
                                  verticalAlign: "middle",
                                }}
                                color="secondary"
                              >
                                help
                              </Icon>
                            </Tooltip>
                          </SoftTypography>
                        </SoftBox>
                        <SoftBox mt={2}>
                          <SoftTypography
                            variant="h5"
                            fontWeight="bold"
                            textAlign="center"
                            color="primary"
                          >
                            {dayjs(pedido?.fechaPedido).format("DD/MM/YYYY")}
                          </SoftTypography>
                        </SoftBox>
                      </SoftBox>
                    </Card>
                  </SoftBox>
                </Slide>
                <Slide in={open} direction="up" mountOnEnter unmountOnExit>
                  <SoftBox>
                    <Card
                      sx={{
                        width: "100%",
                      }}
                    >
                      <SoftBox p={2}>
                        <SoftBox display="flex" alignItems="center">
                          <SoftBox mr={1} display="flex" alignItems="center">
                            <Icon color="primary">access_time</Icon>
                          </SoftBox>
                          <SoftTypography variant="h6">Fecha de creación</SoftTypography>
                        </SoftBox>
                        <SoftBox mt={2}>
                          <SoftTypography
                            variant="h5"
                            fontWeight="bold"
                            textAlign="center"
                            color="primary"
                          >
                            {dayjs(pedido?.createdAt).format("DD/MM/YYYY HH:mm")}
                          </SoftTypography>
                        </SoftBox>
                      </SoftBox>
                    </Card>
                  </SoftBox>
                </Slide>
                <Slide in={open} direction="up" mountOnEnter unmountOnExit>
                  <SoftBox>
                    <Card
                      sx={{
                        width: "100%",
                      }}
                    >
                      <SoftBox p={2}>
                        <SoftBox display="flex" alignItems="center">
                          <SoftBox mr={1} display="flex" alignItems="center">
                            <Icon color="primary">attach_money</Icon>
                          </SoftBox>
                          <SoftTypography variant="h6">Medio de pago</SoftTypography>
                        </SoftBox>
                        <SoftBox mt={2}>
                          <SoftTypography
                            variant="h5"
                            fontWeight="bold"
                            textAlign="center"
                            color="primary"
                          >
                            {pedido?.cuentaCorriente
                              ? "Cuenta corriente"
                              : pedido?.venta?.pagos?.[0]?.medioDePago?.nombre}
                          </SoftTypography>
                        </SoftBox>
                      </SoftBox>
                    </Card>
                  </SoftBox>
                </Slide>
                <Slide in={open} direction="up" mountOnEnter unmountOnExit>
                  <SoftBox
                    sx={{
                      width: "100%",
                    }}
                  >
                    <Card
                      sx={{
                        border: "1px solid rgba(0, 0, 0, 0.12)",
                        zIndex: 2,
                      }}
                    >
                      <SoftBox
                        display="flex"
                        justifyContent="space-between"
                        px={2}
                        py={1}
                        sx={{
                          borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                        }}
                      >
                        <SoftBox display="flex" alignItems="center">
                          <SoftBox mr={1} display="flex" alignItems="center">
                            <Icon color="primary">shopping_cart</Icon>
                          </SoftBox>
                          <SoftTypography variant="h6" fontWeight="bold">
                            Productos
                          </SoftTypography>
                        </SoftBox>
                      </SoftBox>
                      <Collapse
                        in={open}
                        sx={{
                          maxHeight: {
                            xs: "100%",
                            sm: "50vh",
                          },
                          overflowY: "auto",
                        }}
                      >
                        {pedido?.detalles?.map((item, index) => (
                          <Slide in={open} key={index} direction="up" mountOnEnter unmountOnExit>
                            <SoftBox>
                              <SoftBox
                                px={2}
                                py={1}
                                sx={{
                                  borderBottom:
                                    index === pedido?.detalles?.length - 1
                                      ? "none"
                                      : "1px solid rgba(0, 0, 0, 0.12)",
                                  "&:hover": {
                                    backgroundColor: "rgba(0, 0, 0, 0.04)",
                                  },
                                }}
                              >
                                <SoftBox
                                  display={{
                                    xs: "flex-row",
                                    sm: "flex",
                                  }}
                                  justifyContent="space-between"
                                >
                                  <SoftBox
                                    display="flex"
                                    alignItems="center"
                                    justifyContent={{
                                      xs: "space-between",
                                      sm: "flex-start",
                                    }}
                                  >
                                    <SoftBox display="flex" alignItems="center">
                                      <SoftBox
                                        mr={{
                                          xs: 0,
                                          sm: 1,
                                        }}
                                        sx={{
                                          display: {
                                            xs: "none",
                                            sm: "block",
                                          },
                                        }}
                                      >
                                        <Tooltip
                                          title={item?.producto?.categoriaProducto?.categoria}
                                          placement="top"
                                        >
                                          <Icon
                                            sx={{
                                              color: getUproColor("uproYellow"),
                                              cursor: "pointer",
                                            }}
                                          >
                                            {getFoodIcon(
                                              item?.producto?.categoriaProducto?.categoria
                                            )}
                                          </Icon>
                                        </Tooltip>
                                      </SoftBox>
                                      <SoftBox display="flex" flexDirection="column">
                                        <SoftBox display="flex" alignItems="center" mr={2}>
                                          <SoftTypography variant="h6">
                                            {item?.producto.nombre}
                                          </SoftTypography>
                                        </SoftBox>
                                      </SoftBox>
                                    </SoftBox>
                                  </SoftBox>
                                  <SoftBox
                                    display="flex"
                                    justifyContent="flex-end"
                                    mb={{
                                      xs: 1,
                                      sm: 0,
                                    }}
                                    mt={{
                                      xs: 2,
                                      sm: 0,
                                    }}
                                    alignItems="center"
                                  >
                                    <SoftBox
                                      display="flex"
                                      flexDirection="column"
                                      alignItems="flex-end"
                                    >
                                      <SoftTypography
                                        variant="h6"
                                        color={"primary"}
                                        sx={{
                                          whiteSpace: "nowrap",
                                        }}
                                      >
                                        $ {formatMoneyPunto(item?.precio)}
                                      </SoftTypography>
                                    </SoftBox>
                                  </SoftBox>
                                </SoftBox>
                              </SoftBox>
                            </SoftBox>
                          </Slide>
                        ))}
                      </Collapse>
                    </Card>
                    <Card
                      sx={{
                        borderTopLeftRadius: 0,
                        borderTopRightRadius: 0,
                        position: "sticky",
                        marginTop: -2,
                        zIndex: 1,
                      }}
                    >
                      <SoftBox bgColor="primary">
                        <SoftBox
                          display="flex"
                          justifyContent="center"
                          bgColor="primary"
                          p={2}
                          pt={4}
                        >
                          <SoftTypography variant="h6" fontWeight="bold" color="white">
                            Total: $ {formatMoneyPunto(pedido?.total)}
                          </SoftTypography>
                        </SoftBox>
                      </SoftBox>
                    </Card>
                  </SoftBox>
                </Slide>
              </SoftBox>
            </SoftBox>
          </SoftBox>
        </Card>
      </Fade>
    </Modal>
  );
}

ModalDetallesPedido.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  idPedido: PropTypes.string,
};
