// React
import { gql, useMutation, useQuery } from "@apollo/client";
import { useContext, useEffect, useState } from "react";

// Components
import { Card, Grid, Icon, Menu, MenuItem, Tooltip } from "@mui/material";
import Footer from "components/Footer";
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import ModalDelete from "components/Modals/Delete";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";

// Data
import Loading from "components/Carteles/Loading";
import SinDatos from "components/Carteles/SinDatos";
import CustomPagination from "components/Pagination";
import SoftInput from "components/SoftInput";
import DataTable from "components/Tables/DataTable";
import { MessageManager } from "context";
import { UserContext } from "context/user";
import { getUproColor } from "utils/colors";
import dataDocentes from "./data/dataDocentes";
import ModalDocente from "./ModalDocente";
import ModalPuntaje from "./ModalPuntaje";
import ModalSeleccionSede from "./ModalSede";

function Docentes() {
  const [rows, setRows] = useState([]);
  const [search, setSearch] = useState("");
  const [searchTimeout, setSearchTimeout] = useState(null);
  const [openMenu, setOpenMenu] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openModalAdd, setOpenModalAdd] = useState(false);
  const [selectedUsuario, setSelectedUsuario] = useState(null);
  const [openPuntajeMenu, setOpenPuntajeMenu] = useState(false);
  const [openModalSeleccionSede, setOpenModalSeleccionSede] = useState(false);
  const [page, setPage] = useState(1);
  const [selectedSede, setSelectedSede] = useState();
  const [pagesInfo, setPagesInfo] = useState({
    pages: 0,
    total: 0,
  });
  const [orderBy, setOrderBy] = useState({
    campo: "nombreCompleto",
    orden: "ASC",
  });
  const [openModalDelete, setOpenModalDelete] = useState(false);

  const { handleSnackbar } = useContext(MessageManager);
  const { verificarAcceso } = useContext(UserContext);
  const { columns, getRows } = dataDocentes;

  const handleOrderBy = (campo) => {
    if (orderBy.campo === campo) {
      if (orderBy.orden === "ASC") {
        setOrderBy({
          campo: campo,
          orden: "DESC",
        });
      } else {
        setOrderBy({
          campo: campo,
          orden: "ASC",
        });
      }
    } else {
      setOrderBy({
        campo: campo,
        orden: "ASC",
      });
    }
  };

  const handleOpenMenu = (event, item) => {
    setAnchorEl(event.currentTarget);
    setOpenMenu(true);
    setSelectedUsuario(item);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setOpenMenu(false);
  };

  const handleSearch = (event) => {
    const newTimeout = setTimeout(() => {
      setSearch(event.target.value);
      setPage(1);
    }, 500);
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }
    setSearchTimeout(newTimeout);
  };

  const [deleteUser] = useMutation(
    gql`
      mutation deleteUser($id: ID!) {
        deleteUser(id: $id) {
          id
        }
      }
    `
  );

  const { loading, error, data, refetch } = useQuery(
    gql`
      query getUsuarios($search: String, $page: Int, $order: Order) {
        paginationInfo {
          pages
          total
        }
        usuarios(
          search: $search
          pagination: { page: $page, limit: 10 }
          order: $order
          filter: { tipoUser: "Docente" }
        ) {
          id
          nombre
          tipoUser
          idsPermisos
          apellido
          username
          tempPassword
          email
          tipoUser
          nroLegajo
          dni
          fechaNacimiento
          pais {
            id
          }
          genero
          esDiscapacitado
          discapacidad
          materias {
            cargo
            materia {
              id
              nombre
            }
          }
        }
      }
    `,
    {
      variables: {
        search: search || null,
        page: page,
        order: { field: orderBy.campo, order: orderBy.orden },
      },
      fetchPolicy: "no-cache",
      cachePolicy: "no-cache",
    }
  );

  const renderMenu = (
    <Menu id="simple-menu" anchorEl={anchorEl} open={Boolean(openMenu)} onClose={handleCloseMenu}>
      <MenuItem
        disabled={!verificarAcceso(11)}
        onClick={() => {
          setOpenModalAdd(true);
          handleCloseMenu();
        }}
      >
        <SoftBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            width: "100%",
          }}
        >
          <SoftBox>
            <SoftTypography variant="p">Editar</SoftTypography>
          </SoftBox>
          <SoftBox>
            <Icon
              fontSize="small"
              sx={{
                verticalAlign: "middle",
                color: getUproColor("uproGreen"),
              }}
            >
              edit
            </Icon>
          </SoftBox>
        </SoftBox>
      </MenuItem>
      <MenuItem
        disabled={!verificarAcceso([25, 26, 27], "or")}
        onClick={() => {
          setOpenModalSeleccionSede(true);
          handleCloseMenu();
        }}
      >
        <SoftBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            width: "100%",
          }}
        >
          <SoftBox mr={2}>
            <SoftTypography variant="p">Evaluación docente</SoftTypography>
          </SoftBox>
          <SoftBox>
            <Icon
              fontSize="small"
              sx={{
                verticalAlign: "middle",
                color: getUproColor("uproYellow"),
              }}
            >
              quiz
            </Icon>
          </SoftBox>
        </SoftBox>
      </MenuItem>
      <MenuItem
        disabled={!verificarAcceso(12) || selectedUsuario?.tipoUser === "Administrador"}
        onClick={() => {
          setOpenModalDelete(true);
          handleCloseMenu();
        }}
      >
        <SoftBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            width: "100%",
          }}
        >
          <SoftBox>
            <SoftTypography variant="p">Eliminar</SoftTypography>
          </SoftBox>
          <SoftBox>
            <Icon
              fontSize="small"
              sx={{
                verticalAlign: "middle",
                color:
                  selectedUsuario?.tipoUser === "Administrador"
                    ? getUproColor()
                    : getUproColor("uproPink"),
              }}
            >
              delete
            </Icon>
          </SoftBox>
        </SoftBox>
      </MenuItem>
    </Menu>
  );

  const handleDeleteUser = async (id) => {
    try {
      await deleteUser({ variables: { id } });
      refetch();
      handleSnackbar("Docente eliminado correctamente", "success");
    } catch (e) {
      handleSnackbar(e.message || "Error al eliminar el docente", "error");
    }
  };

  useEffect(() => {
    if (data) {
      setRows(getRows(data.usuarios, handleOpenMenu));
      setPagesInfo(data.paginationInfo);
    }
  }, [data]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox py={3}>
        <SoftBox mb={3}>
          <Card>
            <Grid container spacing={2} p={3}>
              <Grid item xs={12}>
                <SoftBox
                  display={{
                    xs: "flex-row",
                    sm: "flex",
                  }}
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <SoftBox display="flex" justifyContent="space-between" alignItems="center">
                    <SoftTypography variant="h6">Docentes</SoftTypography>
                    <SoftBox
                      display={{
                        xs: "block",
                        sm: "none",
                      }}
                    >
                      <Tooltip title="Agregar nuevo docente" placement="top">
                        <SoftButton
                          color="primary"
                          circular
                          iconOnly
                          onClick={() => setOpenModalAdd(true)}
                          disabled={!verificarAcceso(10)}
                        >
                          <Icon>add</Icon>
                        </SoftButton>
                      </Tooltip>
                    </SoftBox>
                  </SoftBox>
                  <SoftBox
                    display="flex"
                    alignItems="center"
                    justifyContent="end"
                    mt={{
                      xs: 2,
                      sm: 0,
                    }}
                  >
                    <SoftBox
                      mr={{
                        xs: 0,
                        sm: 2,
                      }}
                      sx={{
                        width: {
                          xs: "100%",
                          sm: "auto",
                        },
                      }}
                    >
                      <SoftInput
                        placeholder="Escribe aquí..."
                        icon={{ component: "search", direction: "left" }}
                        onChange={handleSearch}
                      />
                    </SoftBox>
                    <SoftBox
                      display={{
                        xs: "none",
                        sm: "block",
                      }}
                    >
                      <Tooltip title="Agregar nuevo docente" placement="top">
                        <SoftButton
                          color="primary"
                          circular
                          onClick={() => setOpenModalAdd(true)}
                          disabled={!verificarAcceso(10)}
                        >
                          <Icon>add</Icon>
                          &nbsp;Agregar
                        </SoftButton>
                      </Tooltip>
                    </SoftBox>
                  </SoftBox>
                </SoftBox>
              </Grid>
            </Grid>
            <SoftBox
              sx={{
                "& .MuiTableRow-root:not(:last-child)": {
                  "& td": {
                    borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                      `${borderWidth[1]} solid ${borderColor}`,
                  },
                },
              }}
            >
              {loading ? (
                <Loading />
              ) : rows?.length > 0 ? (
                <DataTable columns={columns} rows={rows} order={orderBy} setOrder={handleOrderBy} />
              ) : (
                <SinDatos />
              )}
            </SoftBox>
            <CustomPagination
              loading={loading}
              length={data?.usuarios.length}
              total={pagesInfo?.total}
              pages={pagesInfo?.pages}
              page={page}
              setPage={setPage}
              noCard
            />
          </Card>
          {renderMenu}
          <ModalDocente
            open={openModalAdd}
            handleClose={() => {
              setOpenModalAdd(false);
              setSelectedUsuario();
            }}
            refetch={refetch}
            selectedUsuario={selectedUsuario}
            tipo="Docente"
          />
          <ModalDelete
            open={openModalDelete}
            handleClose={() => setOpenModalDelete(false)}
            nombre={`el docente ${selectedUsuario?.nombre} ${selectedUsuario?.apellido}`}
            functionDelete={() => {
              handleDeleteUser(selectedUsuario.id);
              setOpenModalDelete(false);
              setSelectedUsuario();
            }}
          />
          <ModalPuntaje
            open={openPuntajeMenu}
            handleClose={() => setOpenPuntajeMenu(false)}
            docente={selectedUsuario}
            idSede={selectedSede}
          />
          <ModalSeleccionSede
            open={openModalSeleccionSede}
            handleClose={() => setOpenModalSeleccionSede(false)}
            docente={selectedUsuario}
            handleNext={(idSede) => {
              setSelectedSede(idSede);
              setOpenModalSeleccionSede(false);
              setOpenPuntajeMenu(true);
            }}
          />
        </SoftBox>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Docentes;
