import { Card, Fade, Grid, Icon, Modal, Tooltip } from "@mui/material";
import cover from "assets/images/cover.png";
import logo from "assets/images/logo.png";
import logo_alt from "assets/images/logo_alt.png";
import ModalLoading from "components/Modals/Loading";
import CustomPagination from "components/Pagination";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import DataTable from "components/Tables/DataTable";
import { MessageManager } from "context";
import dayjs from "dayjs";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import dataActa from "layouts/autogestion-docente/data/dataActa";
import PropTypes from "prop-types";
import { useContext, useEffect, useState } from "react";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxHeight: "90%",
  width: { xs: "90%", sm: "70%", xxl: "60%" },
  overflowY: "auto",
};

export default function ModalActa({ open, handleClose, acta, alumnos }) {
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(1);
  const [pagesInfo, setPagesInfo] = useState({ pages: 0, total: 0 });
  const { columns, getRows } = dataActa;
  const [loading, setLoading] = useState(false);
  const { handleSnackbar } = useContext(MessageManager);

  const generatePDF = async () => {
    setLoading(true);
    handleSnackbar("Generando documento, por favor espere", "info");
    const element = document.getElementById("certificado");
    if (element) {
      const canvas = await html2canvas(element, { scale: 2 });
      const report = new jsPDF({
        orientation: "portrait",
        compress: true,
      });

      const pdfWidth = report.internal.pageSize.getWidth();
      const pdfHeight = (canvas.height * pdfWidth) / canvas.width;

      const addPageToPDF = async (page) => {
        setPage(page);
        await new Promise((resolve) => setTimeout(resolve, 100));
        const canvas = await html2canvas(element, { scale: 2 });
        const imgData = canvas.toDataURL("image/png");
        if (page > 1) {
          report.addPage();
        }
        report.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
        if (page < pagesInfo.pages) {
          await addPageToPDF(page + 1);
        } else {
          report.save(`Acta Nro. ${acta.id} - ${acta.examen.titulo} - ${acta.comision.nombre}.pdf`);
        }
      };

      await addPageToPDF(1);
      setPage(1);
      handleSnackbar("Documento generado correctamente", "success");
    }
    setLoading(false);
  };

  useEffect(() => {
    if (alumnos && open) {
      setRows(getRows(alumnos.slice((page - 1) * 10, page * 10)));
      setPagesInfo({ pages: Math.ceil(alumnos.length / 10), total: alumnos.length });
    } else {
      setRows([]);
      setPagesInfo({ pages: 0, total: 0 });
      setPage(1);
    }
  }, [alumnos, open, page]);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={() => {
        if (!loading) handleClose();
      }}
      closeAfterTransition
    >
      <Fade in={open}>
        <Card sx={style}>
          <SoftBox>
            <ModalLoading open={loading} />
            <SoftBox display="flex" justifyContent="space-between" p={3} pb={0}>
              <SoftBox>
                <SoftTypography variant="h6">Acta</SoftTypography>
              </SoftBox>
              <SoftBox display="flex" justifyContent="flex-end" alignItems="center">
                <SoftBox display="flex" alignItems="center">
                  <Tooltip title="Cerrar" placement="top">
                    <Icon
                      fontSize="medium"
                      onClick={() => {
                        if (!loading) handleClose();
                      }}
                      sx={{ cursor: "pointer" }}
                    >
                      close
                    </Icon>
                  </Tooltip>
                </SoftBox>
              </SoftBox>
            </SoftBox>
            <SoftBox id="certificado" p={3}>
              <SoftBox position="relative">
                <SoftBox
                  display="flex"
                  alignItems="top"
                  position="relative"
                  minHeight="18vh"
                  borderRadius="xl"
                  sx={{
                    backgroundImage: ({
                      functions: { rgba, linearGradient },
                      palette: { gradients },
                    }) =>
                      `${linearGradient(
                        rgba(gradients.primary.main, 0.6),
                        rgba(gradients.primary.state, 0.6)
                      )}, url(${cover})`,
                    backgroundSize: "cover",
                    backgroundPosition: "50%",
                    overflow: "hidden",
                  }}
                >
                  <Grid container justifyContent="center" p={4} mb={4} alignItems={"center"}>
                    <Grid item xs={12} sm={3}>
                      <SoftBox
                        component="img"
                        src={logo_alt}
                        alt="UPrO Logo"
                        width="100%"
                        p={3}
                        sx={{
                          minWidth: "200px",
                        }}
                      />
                    </Grid>
                  </Grid>
                </SoftBox>
                <Card
                  sx={{
                    backdropFilter: `saturate(200%) blur(30px)`,
                    backgroundColor: ({ functions: { rgba }, palette: { white } }) =>
                      rgba(white.main, 1),
                    boxShadow: ({ boxShadows: { navbarBoxShadow } }) => navbarBoxShadow,
                    position: "relative",
                    mt: -8,
                    mx: 2,
                    py: 2,
                    px: 2,
                  }}
                >
                  <SoftBox display="flex" justifyContent="space-between">
                    <SoftBox>
                      <SoftTypography variant="h6" fontWeight="light">
                        Número de acta
                      </SoftTypography>
                      <SoftTypography variant="h6" fontWeight="bold">
                        {acta?.id}
                      </SoftTypography>
                    </SoftBox>
                    <SoftBox textAlign="right">
                      <SoftTypography variant="h6" fontWeight="light">
                        Fecha de emisión
                      </SoftTypography>
                      <SoftTypography variant="h6" fontWeight="bold">
                        {dayjs(acta?.createdAt).format("DD/MM/YYYY HH:mm")}
                      </SoftTypography>
                    </SoftBox>
                  </SoftBox>
                  <Grid container spacing={2} mt={1}>
                    <Grid item xs={12}>
                      <SoftTypography variant="h5" fontWeight="bold" textAlign="center">
                        {`${acta?.examen?.titulo} - ${acta?.comision?.codigo}/${acta?.comision?.nombre}`}
                      </SoftTypography>
                    </Grid>
                    <Grid item xs={12}>
                      <SoftTypography variant="h6" fontWeight="light">
                        El presente certificado acredita que los alumnos que se detallan a
                        continuación han rendido el examen
                        <strong> {acta?.examen?.titulo} </strong> de la unidad de competencia
                        <strong>
                          {" "}
                          {acta?.examen?.materia?.codigo}/{acta?.examen?.materia?.nombre}{" "}
                        </strong>{" "}
                        correspondiente{" "}
                        {`${
                          acta?.examen?.materia?.nivel?.planEstudio?.carrera?.tipo ===
                          "Curso de Ingreso"
                            ? "al curso"
                            : "a la carrera"
                        }`}
                        <strong>
                          {" "}
                          {`${acta?.examen?.materia?.nivel?.planEstudio?.carrera?.codigo} - ${acta?.examen?.materia?.nivel?.planEstudio?.carrera?.nombre} / ${acta?.examen?.materia?.nivel?.planEstudio?.codigo} - ${acta?.examen?.materia?.nivel?.planEstudio?.nombre} / ${acta?.examen?.materia?.nivel?.codigo} - ${acta?.examen?.materia?.nivel?.nombre} / ${acta?.examen?.materia?.codigo} - ${acta?.examen?.materia?.nombre}`}{" "}
                        </strong>
                        en la comisión
                        <strong>
                          {" "}
                          {acta?.comision?.codigo}/{acta?.comision?.nombre}{" "}
                        </strong>{" "}
                        el día
                        <strong> {dayjs(acta?.examen?.fecha).format("DD/MM/YYYY")} </strong>.
                      </SoftTypography>
                    </Grid>
                    <Grid item xs={12}>
                      <SoftBox
                        sx={{
                          "& .MuiTableRow-root:not(:last-child)": {
                            "& td": {
                              borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                                `${borderWidth[1]} solid ${borderColor}`,
                            },
                          },
                        }}
                      >
                        <DataTable columns={columns} rows={rows} />
                        <CustomPagination
                          loading={false}
                          length={rows?.length}
                          total={alumnos?.length}
                          pages={pagesInfo?.pages}
                          page={page}
                          setPage={setPage}
                          noCard
                        />
                      </SoftBox>
                    </Grid>
                  </Grid>
                  <SoftBox display="flex" justifyContent="center" mt={3} alignItems="end">
                    <SoftBox component="img" src={logo} alt="UPrO Logo" width="90px" />
                  </SoftBox>
                  <SoftBox display="flex" justifyContent="center" mt={3} alignItems="center">
                    <SoftBox textAlign="center">
                      <SoftTypography variant="caption" fontWeight="light">
                        Powered by&nbsp;
                      </SoftTypography>
                      <SoftTypography variant="caption" fontWeight="bold">
                        UPrO
                      </SoftTypography>
                    </SoftBox>
                  </SoftBox>
                </Card>
              </SoftBox>
            </SoftBox>
            <SoftBox
              p={3}
              pt={0}
              display={{
                xs: "none",
                sm: "flex",
              }}
              justifyContent="flex-end"
            >
              <SoftButton color="primary" onClick={generatePDF} disabled={loading} circular>
                <Icon>download</Icon>
                &nbsp;Descargar
              </SoftButton>
            </SoftBox>
          </SoftBox>
        </Card>
      </Fade>
    </Modal>
  );
}

ModalActa.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  acta: PropTypes.object,
  alumnos: PropTypes.array,
};
