// React

// Components
import { Card, Collapse, Grid, Icon, Tooltip } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import PropTypes from "prop-types";

// Data
import { gql, useQuery } from "@apollo/client";
import SoftBadge from "components/SoftBadge";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { API_URL } from "services/config";
import { getUproColor } from "utils/colors";
import ModalCertificado from "../ModalCertificado";
import ModalCertificadoComision from "layouts/alumnos/components/ModalCertificadoComision";

export default function Certificados({ usuario, loading, carrera }) {
  const tiposCertificados = [
    {
      nombre: "Certificado de Preinscripción",
    },
    {
      nombre: "Certificado de Inscripción Libre",
      disabled: true,
    },
    {
      nombre: "Certificado de Inscripción a Comisiones",
    },
  ];
  const [expanded, setExpanded] = useState([]);
  const [openModalCertificado, setOpenModalCertificado] = useState();
  const [openModalCertificadoComision, setOpenModalCertificadoComision] = useState(false);
  const { data: certificados, refetch } = useQuery(gql`
    query getCertificados {
      certificados(
        pagination: { page: 1, limit: 5 }
        order: { field: "id", order: DESC }
        filter: { url: { _not: null } }
      ) {
        id
        tipo
        createdAt
        url
      }
    }
  `);

  useEffect(() => {
    refetch();
  }, [openModalCertificado]);

  return (
    <SoftBox>
      <Grid container spacing={2} alignItems="flex-start" id="certificadosCard">
        <Grid item xs={12} xxl={6} container spacing={2}>
          {tiposCertificados.map(
            (tipo, index) =>
              index % 2 === 0 && (
                <Grid item xs={12} key={index}>
                  <Card>
                    <SoftBox p={2}>
                      <SoftBox display="flex" justifyContent="space-between" alignItems="center">
                        <SoftBox display="flex" alignItems="center">
                          <SoftBox mr={1} display="flex" alignItems="center">
                            <SoftBox
                              display="grid"
                              justifyContent="center"
                              alignItems="center"
                              bgColor="primary"
                              color="white"
                              width="3rem"
                              height="3rem"
                              shadow="md"
                              borderRadius="lg"
                              variant="gradient"
                            >
                              <Icon fontSize="default">description</Icon>
                            </SoftBox>
                          </SoftBox>
                          <SoftBox
                            display="flex-row"
                            alignItems="center"
                            sx={{
                              lineHeight: 0,
                            }}
                          >
                            <SoftTypography variant="h6" fontWeight="bold">
                              {tipo.nombre}
                            </SoftTypography>
                            <SoftTypography variant="caption" fontWeight="regular">
                              {certificados?.certificados?.filter(
                                (certificado) => certificado.tipo === tipo.nombre
                              ).length
                                ? `Último certificado generado: ${dayjs(
                                    certificados?.certificados?.filter(
                                      (certificado) => certificado.tipo === tipo.nombre
                                    )[0].createdAt
                                  ).format("DD/MM/YYYY HH:mm")}`
                                : "No hay certificados generados"}
                            </SoftTypography>
                          </SoftBox>
                        </SoftBox>
                        <SoftBox
                          display={{
                            xs: "none",
                            sm: "block",
                          }}
                        >
                          <Tooltip title="Generar certificado" placement="top">
                            <SoftButton
                              circular
                              color="uproGreen"
                              onClick={() => {
                                if (tipo.nombre === "Certificado de Inscripción a Comisiones") {
                                  setOpenModalCertificadoComision(true);
                                } else {
                                  setOpenModalCertificado(tipo.nombre);
                                }
                              }}
                              disabled={tipo.disabled}
                            >
                              <Icon>add</Icon>
                              &nbsp; Generar
                            </SoftButton>
                          </Tooltip>
                        </SoftBox>
                      </SoftBox>
                      <SoftBox mt={2}>
                        <Card
                          sx={{
                            border: "1px solid rgba(0, 0, 0, 0.12)",
                          }}
                        >
                          <SoftBox
                            display="flex"
                            justifyContent="space-between"
                            px={2}
                            py={1}
                            sx={{
                              borderBottom: !expanded.includes(tipo.nombre)
                                ? "none"
                                : "1px solid rgba(0, 0, 0, 0.12)",
                              cursor: "pointer",
                              "&:hover": {
                                backgroundColor: "rgba(0, 0, 0, 0.04)",
                              },
                            }}
                            onClick={(e) => {
                              setExpanded(
                                expanded.includes(tipo.nombre)
                                  ? expanded.filter((i) => i !== tipo.nombre)
                                  : [...expanded, tipo.nombre]
                              );
                            }}
                          >
                            <SoftBox display="flex" alignItems="center">
                              <SoftBox mr={1} display="flex" alignItems="center">
                                <Icon
                                  sx={{
                                    color: getUproColor("uproGreen"),
                                  }}
                                >
                                  history
                                </Icon>
                              </SoftBox>
                              <SoftTypography variant="h6" fontWeight="bold">
                                Historial de certificados
                              </SoftTypography>
                            </SoftBox>
                            <SoftBox display="flex" justifyContent="flex-end" alignItems="center">
                              <SoftBox>
                                <Tooltip
                                  title={expanded.includes(tipo.nombre) ? "Ocultar" : "Mostrar"}
                                  placement="top"
                                >
                                  <SoftButton
                                    color="primary"
                                    circular
                                    iconOnly
                                    onClick={() =>
                                      setExpanded(
                                        expanded.includes(tipo.nombre)
                                          ? expanded.filter((i) => i !== tipo.nombre)
                                          : [...expanded, tipo.nombre]
                                      )
                                    }
                                  >
                                    <Icon
                                      sx={{
                                        rotate: expanded.includes(tipo.nombre) ? "180deg" : "0deg",
                                        transition: "all 0.5s",
                                      }}
                                    >
                                      expand_more
                                    </Icon>
                                  </SoftButton>
                                </Tooltip>
                              </SoftBox>
                            </SoftBox>
                          </SoftBox>
                          <Collapse in={expanded.includes(tipo.nombre)}>
                            {certificados?.certificados
                              ?.filter((certificado) => certificado.tipo === tipo.nombre)
                              ?.map((certificado, index) => (
                                <SoftBox key={certificado.id}>
                                  <SoftBox
                                    px={2}
                                    py={1}
                                    sx={{
                                      borderBottom:
                                        certificados?.certificados?.filter(
                                          (certificado) => certificado.tipo === tipo.nombre
                                        ).length -
                                          1 ===
                                        index
                                          ? "none"
                                          : "1px solid rgba(0, 0, 0, 0.12)",
                                      cursor: "pointer",
                                      "&:hover": {
                                        backgroundColor: "rgba(0, 0, 0, 0.04)",
                                      },
                                    }}
                                  >
                                    <SoftBox display="flex" justifyContent="space-between">
                                      <SoftBox
                                        display="flex"
                                        alignItems="center"
                                        pl={{
                                          xs: 0,
                                          sm: 2,
                                        }}
                                      >
                                        <SoftBox
                                          mr={1}
                                          sx={{
                                            display: {
                                              xs: "none",
                                              sm: "block",
                                            },
                                          }}
                                        >
                                          <Icon
                                            sx={{
                                              color: getUproColor("uproPink"),
                                            }}
                                          >
                                            subdirectory_arrow_right
                                          </Icon>
                                        </SoftBox>
                                        <SoftBox
                                          mr={1}
                                          sx={{
                                            display: {
                                              xs: "none",
                                              sm: "block",
                                            },
                                          }}
                                        >
                                          <Tooltip title="Nivel" placement="top">
                                            <Icon
                                              sx={{
                                                color: getUproColor("uproYellow"),
                                              }}
                                            >
                                              article
                                            </Icon>
                                          </Tooltip>
                                        </SoftBox>
                                        <SoftTypography variant="h6">{`${certificado?.id} - ${certificado?.tipo}`}</SoftTypography>
                                        <SoftBox
                                          ml={1}
                                          mr={{
                                            xs: 1,
                                            sm: 0,
                                          }}
                                          display="flex"
                                          alignItems="center"
                                        >
                                          <SoftBadge
                                            color="uproYellow"
                                            badgeContent={`${dayjs(certificado?.createdAt).format(
                                              "DD/MM/YYYY"
                                            )}`}
                                          />
                                        </SoftBox>
                                      </SoftBox>
                                      <SoftBox
                                        display="flex"
                                        justifyContent="flex-end"
                                        alignItems="center"
                                      >
                                        <SoftBox>
                                          <Tooltip title={"Descargar"} placement="top">
                                            <SoftButton
                                              color="uproGreen"
                                              circular
                                              iconOnly
                                              onClick={async () => {
                                                const file = await fetch(
                                                  `${API_URL}/${certificado.url}`
                                                );
                                                //cambiar el nombre del archivo
                                                const name = `${certificado.id} - ${certificado.tipo} - ${usuario.dni}.pdf`;
                                                const blob = await file.blob();
                                                const url = URL.createObjectURL(blob);

                                                const a = document.createElement("a");
                                                a.href = url;
                                                a.download = name;
                                                a.click();
                                              }}
                                            >
                                              <Icon>download</Icon>
                                            </SoftButton>
                                          </Tooltip>
                                        </SoftBox>
                                      </SoftBox>
                                    </SoftBox>
                                  </SoftBox>
                                </SoftBox>
                              ))}
                          </Collapse>
                        </Card>
                      </SoftBox>
                    </SoftBox>
                  </Card>
                </Grid>
              )
          )}
        </Grid>
        <Grid item xs={12} xxl={6} container spacing={2}>
          {tiposCertificados.map(
            (tipo, index) =>
              index % 2 !== 0 && (
                <Grid item xs={12} key={index}>
                  <Card>
                    <SoftBox p={2}>
                      <SoftBox display="flex" justifyContent="space-between" alignItems="center">
                        <SoftBox display="flex" alignItems="center">
                          <SoftBox mr={1} display="flex" alignItems="center">
                            <SoftBox
                              display="grid"
                              justifyContent="center"
                              alignItems="center"
                              bgColor="primary"
                              color="white"
                              width="3rem"
                              height="3rem"
                              shadow="md"
                              borderRadius="lg"
                              variant="gradient"
                            >
                              <Icon fontSize="default">description</Icon>
                            </SoftBox>
                          </SoftBox>
                          <SoftBox
                            display="flex-row"
                            alignItems="center"
                            sx={{
                              lineHeight: 0,
                            }}
                          >
                            <SoftTypography variant="h6" fontWeight="bold">
                              {tipo.nombre}
                            </SoftTypography>
                            <SoftTypography variant="caption" fontWeight="regular">
                              {certificados?.certificados?.filter(
                                (certificado) => certificado.tipo === tipo.nombre
                              ).length
                                ? `Último certificado generado: ${dayjs(
                                    certificados?.certificados?.filter(
                                      (certificado) => certificado.tipo === tipo.nombre
                                    )[0].createdAt
                                  ).format("DD/MM/YYYY HH:mm")}`
                                : "No hay certificados generados"}
                            </SoftTypography>
                          </SoftBox>
                        </SoftBox>
                        <SoftBox
                          display={{
                            xs: "none",
                            sm: "block",
                          }}
                        >
                          <Tooltip title="Generar certificado" placement="top">
                            <SoftButton
                              circular
                              color="uproGreen"
                              onClick={() => {
                                if (tipo.nombre === "Certificado de Inscripción a Comisiones") {
                                  setOpenModalCertificadoComision(true);
                                } else {
                                  setOpenModalCertificado(tipo.nombre);
                                }
                              }}
                              disabled={tipo.disabled}
                            >
                              <Icon>add</Icon>
                              &nbsp; Generar
                            </SoftButton>
                          </Tooltip>
                        </SoftBox>
                      </SoftBox>
                      <SoftBox mt={2}>
                        <Card
                          sx={{
                            border: "1px solid rgba(0, 0, 0, 0.12)",
                          }}
                        >
                          <SoftBox
                            display="flex"
                            justifyContent="space-between"
                            px={2}
                            py={1}
                            sx={{
                              borderBottom: !expanded.includes(tipo.nombre)
                                ? "none"
                                : "1px solid rgba(0, 0, 0, 0.12)",
                              cursor: "pointer",
                              "&:hover": {
                                backgroundColor: "rgba(0, 0, 0, 0.04)",
                              },
                            }}
                            onClick={(e) => {
                              setExpanded(
                                expanded.includes(tipo.nombre)
                                  ? expanded.filter((i) => i !== tipo.nombre)
                                  : [...expanded, tipo.nombre]
                              );
                            }}
                          >
                            <SoftBox display="flex" alignItems="center">
                              <SoftBox mr={1} display="flex" alignItems="center">
                                <Icon
                                  sx={{
                                    color: getUproColor("uproGreen"),
                                  }}
                                >
                                  history
                                </Icon>
                              </SoftBox>
                              <SoftTypography variant="h6" fontWeight="bold">
                                Historial de certificados
                              </SoftTypography>
                            </SoftBox>
                            <SoftBox display="flex" justifyContent="flex-end" alignItems="center">
                              <SoftBox>
                                <Tooltip
                                  title={expanded.includes(tipo.nombre) ? "Ocultar" : "Mostrar"}
                                  placement="top"
                                >
                                  <SoftButton
                                    color="primary"
                                    circular
                                    iconOnly
                                    onClick={() =>
                                      setExpanded(
                                        expanded.includes(tipo.nombre)
                                          ? expanded.filter((i) => i !== tipo.nombre)
                                          : [...expanded, tipo.nombre]
                                      )
                                    }
                                  >
                                    <Icon
                                      sx={{
                                        rotate: expanded.includes(tipo.nombre) ? "180deg" : "0deg",
                                        transition: "all 0.5s",
                                      }}
                                    >
                                      expand_more
                                    </Icon>
                                  </SoftButton>
                                </Tooltip>
                              </SoftBox>
                            </SoftBox>
                          </SoftBox>
                          <Collapse in={expanded.includes(tipo.nombre)}>
                            {certificados?.certificados
                              ?.filter((certificado) => certificado.tipo === tipo.nombre)
                              ?.map((certificado, index) => (
                                <SoftBox key={certificado.id}>
                                  <SoftBox
                                    px={2}
                                    py={1}
                                    sx={{
                                      borderBottom:
                                        certificados?.certificados?.filter(
                                          (certificado) => certificado.tipo === tipo.nombre
                                        ).length -
                                          1 ===
                                        index
                                          ? "none"
                                          : "1px solid rgba(0, 0, 0, 0.12)",
                                      cursor: "pointer",
                                      "&:hover": {
                                        backgroundColor: "rgba(0, 0, 0, 0.04)",
                                      },
                                    }}
                                  >
                                    <SoftBox display="flex" justifyContent="space-between">
                                      <SoftBox
                                        display="flex"
                                        alignItems="center"
                                        pl={{
                                          xs: 0,
                                          sm: 2,
                                        }}
                                      >
                                        <SoftBox
                                          mr={1}
                                          sx={{
                                            display: {
                                              xs: "none",
                                              sm: "block",
                                            },
                                          }}
                                        >
                                          <Icon
                                            sx={{
                                              color: getUproColor("uproPink"),
                                            }}
                                          >
                                            subdirectory_arrow_right
                                          </Icon>
                                        </SoftBox>
                                        <SoftBox
                                          mr={1}
                                          sx={{
                                            display: {
                                              xs: "none",
                                              sm: "block",
                                            },
                                          }}
                                        >
                                          <Tooltip title="Nivel" placement="top">
                                            <Icon
                                              sx={{
                                                color: getUproColor("uproYellow"),
                                              }}
                                            >
                                              article
                                            </Icon>
                                          </Tooltip>
                                        </SoftBox>
                                        <SoftTypography variant="h6">{`${certificado?.id} - ${certificado?.tipo}`}</SoftTypography>
                                        <SoftBox
                                          ml={1}
                                          mr={{
                                            xs: 1,
                                            sm: 0,
                                          }}
                                          display="flex"
                                          alignItems="center"
                                        >
                                          <SoftBadge
                                            color="uproYellow"
                                            badgeContent={`${dayjs(certificado?.createdAt).format(
                                              "DD/MM/YYYY"
                                            )}`}
                                          />
                                        </SoftBox>
                                      </SoftBox>
                                      <SoftBox
                                        display="flex"
                                        justifyContent="flex-end"
                                        alignItems="center"
                                      >
                                        <SoftBox>
                                          <Tooltip title={"Descargar"} placement="top">
                                            <SoftButton
                                              color="uproGreen"
                                              circular
                                              iconOnly
                                              onClick={async () => {
                                                const file = await fetch(
                                                  `${API_URL}/${certificado.url}`
                                                );
                                                //cambiar el nombre del archivo
                                                const name = `${certificado.id} - ${certificado.tipo} - ${usuario.dni}.pdf`;
                                                const blob = await file.blob();
                                                const url = URL.createObjectURL(blob);

                                                const a = document.createElement("a");
                                                a.href = url;
                                                a.download = name;
                                                a.click();
                                              }}
                                            >
                                              <Icon>download</Icon>
                                            </SoftButton>
                                          </Tooltip>
                                        </SoftBox>
                                      </SoftBox>
                                    </SoftBox>
                                  </SoftBox>
                                </SoftBox>
                              ))}
                          </Collapse>
                        </Card>
                      </SoftBox>
                    </SoftBox>
                  </Card>
                </Grid>
              )
          )}
        </Grid>
      </Grid>
      <ModalCertificado
        open={openModalCertificado}
        handleClose={() => setOpenModalCertificado()}
        usuario={usuario}
        carrera={carrera}
        tipo={openModalCertificado}
      />
      <ModalCertificadoComision
        open={openModalCertificadoComision}
        handleClose={() => {
          setOpenModalCertificadoComision(false);
          refetch();
        }}
        sede={usuario?.inscripcionesComisiones[0]?.comision?.sede}
        comisiones={
          usuario?.inscripcionesComisiones?.map((inscripcion) => inscripcion.comision) || []
        }
        alumno={usuario}
        carrera={carrera}
      />
    </SoftBox>
  );
}

Certificados.propTypes = {
  usuario: PropTypes.object,
  carrera: PropTypes.object,
  loading: PropTypes.bool,
};
